















import { Component, Prop, Vue, Provide } from "vue-property-decorator";
import WebhookDetailCard from './WebhookDetailCard.vue';
import WebhookNoTransactionChartCard from './WebhookNoTransactionChartCard.vue';
import WebhookAmountTransactionChartCard from './WebhookAmountTransactionChartCard.vue';
import WebhookTransactionHistoryCard from './WebhookTransactionHistoryCard.vue';

@Component({
  components: {
    WebhookDetailCard,
    WebhookNoTransactionChartCard,
    WebhookAmountTransactionChartCard,
    WebhookTransactionHistoryCard,
  }
})
export default class WebhookDetailPage extends Vue {
  get address(): string {
    return this.$route.query.address as string;
  }

  get coin(): string {
    return this.$route.query.currency as string;
  }
 
}
