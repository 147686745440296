




































import { Component, Watch, Vue } from 'vue-property-decorator';
import { listTransaction, getHook } from '@/apis/hook.api';
import {
  mounted,
  errorPopupHandler,
  errorCatchHandler,
  loadings,
} from '@/utils/decorators/VueTimmer';

@Component({
  props: {
    address: {
      type: String,
    },
    currency: {
      type: String,
    }
  }
})

export default class WebhookTransactionHistoryCard extends Vue {
  address: string;
  currency: string;
  hook: any = null;

  loadings = {
    loadHistory: false
  };

  paging = {
    canNext:false,
    canPrev:false,
  };

  transactionHistory: any[] = [];

  @Watch('id')
  @mounted
  getHookDetail() {
    if (!this.address) {
      return;
    }

    return getHook({address: this.address, coin: this.currency})
      .then(hook => {
        this.hook = hook;
      });
  }

  @mounted
  @loadings("loadings.loadHistory")
  listTransactionHistory() {
    return listTransaction({
      from_id: null,
      address: this.address,
      limit: 5,
      coin: this.currency,
      action: "next"
    }).then(data => {
      console.log(data)
      this.transactionHistory = data.data;
      this.paging.canNext = !data.ended
    });
  }

  @loadings("loadings.loadHistory")
  onPrev(){
    return listTransaction({
      from_id : this.transactionHistory[0] && this.transactionHistory[0].hex_id || "",
      address: this.address,
      coin: this.currency,
      limit: 5,
      action:"previous"
    }).then(data => {
      this.transactionHistory = data.data;
      this.paging.canNext = true
      this.paging.canPrev = !data.ended
    })
  }

  @loadings("loadings.loadHistory")
  onNext(){
    return listTransaction({
      from_id : this.transactionHistory[this.transactionHistory.length - 1] && this.transactionHistory[this.transactionHistory.length - 1].hex_id || "",
      address: this.address,
      coin: this.currency,
      limit: 5,
      action:"next"
    }).then(data => {
      this.transactionHistory = data.data;
      this.paging.canNext = !data.ended
      this.paging.canPrev = true
    })
  }
}
