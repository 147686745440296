






































import { Component, Watch, Vue } from 'vue-property-decorator';
import { getHook } from '@/apis/hook.api';
import { mounted } from "@/utils/decorators/VueTimmer";

@Component({
  props: {
    address: {
      type: String,
    },
    currency: {
      type: String,
    }
  }
})

export default class WebhookDetailCard extends Vue {
  address: string;
  currency: string;
  hook: any = null;

  @Watch('id')
  @mounted
  getHookDetail() {
    if (!this.address) {
      return;
    }

    return getHook({address: this.address, coin: this.currency})
      .then(hook => {
        this.hook = hook;
      });
  }
}
